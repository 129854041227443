<template>
  <div class="yzyt-home">
    <div class="header">
      <div class="header-company">北京诺来咨询有限公司</div>
    </div>
    <div class="address">
      <div class="address__left-text">
        <h2 class="left-text__title">一招易投APP</h2>
        <p class="left-text__describe">投标变得简单高效</p>

        <p class="left-text__tips">深耕招投标行业，服务于招标投标行业所有从业人员，，</p>
        <p class="left-text__tips">提供企业信息查询、资质认证、信息同步、公告订阅等电子化软件服务。</p>
        <img
          class="left-text__download"
          src="@/assets/image/icon-download.png"
          alt=""
          @click="toDownload"
        />
      </div>
      <img class="address__icon" src="@/assets/image/icon-content.png" alt="" />
      <img class="address__qr-img" src="@/assets/image/fix-icon.png" alt="" />
    </div>
    <div class="introduce">
      <h2 class="introduce__title">招投标智能工具</h2>
      <div
        class="introduce__swiper"
        @mouseenter="mouserHandle"
        @mouseleave="mouserLaveHandle"
      >
        <div
          class="swiper-01"
          :class="{ 'swiper-01-active': isActive == '01' }"
          @mouseenter="isActive = '01'"
        >
          <img
            v-if="isActive == '01'"
            class="bottom-img"
            src="@/assets/image/icon-swiper-item01.png"
            alt=""
          />
        </div>
        <div
          :class="{ 'swiper-02-active': isActive == '02' }"
          class="swiper-01"
          @mouseenter="isActive = '02'"
        >
          <img
            v-if="isActive == '02'"
            class="bottom-img"
            src="@/assets/image/icon-swiper-item02.png"
            alt=""
          />
        </div>
        <div
          class="swiper-03"
          :class="{ 'swiper-03-active': isActive == '03' }"
          @mouseenter="isActive = '03'"
        >
          <img
            v-if="isActive == '03'"
            class="bottom-img"
            src="@/assets/image/icon-swiper-item03.png"
            alt=""
          />
        </div>
      </div>
      <div class="introduce__swiper-tips">
        <div
          :class="{ 'item-active': isActive == '01' }"
          class="tips-item"
          @mouseenter="isActive = '01'"
        ></div>
        <div
          :class="{ 'item-active': isActive == '02' }"
          class="tips-item"
          @mouseenter="isActive = '02'"
        ></div>
        <div
          :class="{ 'item-active': isActive == '03' }"
          class="tips-item"
          @mouseenter="isActive = '03'"
        ></div>
      </div>
    </div>
    <div class="service">
      <h2 class="service__title">平台运维</h2>
      <div class="service__gif">
        <img class="gif-img" src="@/assets/image/service.gif" alt="" />
      </div>
      <div class="service__bottom">提供7x24小时技术支持与维护，确保系统稳定高效运行</div>
    </div>
    <div class="bottom">
      <p>版权所有© 北京诺来咨询有限公司</p>
      <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #CFDDE9;flex: 1;">
        <p>ICP备案号：京ICP备2022030516号</p>
      </a>
      <p>地址：北京市海淀区学清路甲18号中关村东升科技园学院园二层A2626号</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data () {
    return {
      isActive: '02',
      intervaler: null
    }
  },
  methods: {
    mouserHandle () {
      clearInterval(this.intervaler)
    },
    mouserLaveHandle () {
      this.intervaler = setInterval(() => {
        if (this.isActive >= '03') {
          this.isActive = '01'
          return
        }
        this.isActive++
      }, 3000)
    },
    toDownload(){
      window.open('https://www.nolai.com.cn/download.html')
    }
  },
  mounted () {
    this.intervaler = setInterval(() => {
      if (this.isActive >= '03') {
        this.isActive = '01'
        return
      }
      this.isActive++
    }, 3000)
  }
}
</script>

<style lang="less" scoped>
.background(@height:378rem,@url: url(@/assets/image/banner@2x.png),@width:100%) {
  width: @width;
  height: @height;
  background-image: @url;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  transition: 0.5s;
  overflow: hidden;
}
.yzyt-home {
  max-width: 1920rem;
}
.header {
  .background();
  .header-company {
    font-weight: 400;
    font-size: 20rem;
    color: #3f544a;
    line-height: 20rem;
    text-align: left;
    position: absolute;
    top: 25rem;
    left: 270rem;
  }
}
.address__qr-img {
  width: 172rem;
  height: 255rem;
  position: fixed;
  top: 470rem;
  right: 39rem;
  z-index: 10;
}
.address {
  .background(527rem,url(@/assets/image/bg-address.png));
  .address__icon {
    width: 712rem;
    height: 411rem;
    position: absolute;
    top: 92rem;
    right: 311rem;
  }
  &__left-text {
    position: absolute;
    left: 278rem;
    top: 166rem;
    .left-text__title {
      font-weight: 400;
      font-size: 40rem;
      color: #223937;
      line-height: 40rem;
      margin-bottom: 17rem;
    }
    .left-text__describe {
      font-weight: 400;
      font-size: 22rem;
      color: #52645f;
      line-height: 20rem;
      letter-spacing: 2rem;
      text-align: left;
      margin-bottom: 25rem;
    }
    .left-text__tips {
      font-weight: 400;
      font-size: 18rem;
      color: #52645f;
      line-height: 28rem;
      text-align: left;
    }
    .left-text__download {
      width: 149rem;
      height: 66rem;
      margin-top: 34rem;
      cursor: pointer;
    }
  }
}
.introduce {
  .background(736rem,url(@/assets/image/bg-swiper.png));
  padding-top: 60rem;
  &__title {
    font-weight: 400;
    font-size: 40rem;
    color: #223937;
    line-height: 40rem;
    text-align: center;
    margin: 0rem auto 17rem;
  }
  &__describe {
    font-weight: 400;
    font-size: 22rem;
    color: #52645f;
    line-height: 20rem;
    letter-spacing: 2rem;
    text-align: center;
    margin: 0 auto 0;
  }
  &__swiper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1380rem;
    margin: auto;
    margin-top: 55rem;
    .swiper-01-active {
      .background(513rem, url(@/assets/image/bg-swiper_item01.png), 710rem) !important;
      margin-right: 25rem;
      .bottom-img {
        position: absolute;
        bottom: -50%; /* 图片从底部开始 */
        animation: slide-up 1s ease-in-out forwards;
        width: 568rem;
        height: 280rem;
        margin-left: 71rem;
      }
    }
    .swiper-02-active {
      .background(513rem, url(@/assets/image/bg-swiper_item02.png), 710rem) !important;
      margin-right: 25rem;

      .bottom-img {
        position: absolute;
        bottom: -50%; /* 图片从底部开始 */
        animation: slide-up 1s ease-in-out forwards;
        width: 568rem;
        height: 280rem;
        margin-left: 71rem;
      }
    }
    .swiper-03-active {
      .background(513rem, url(@/assets/image/bg-swiper_item03.png), 710rem) !important;
      .bottom-img {
        position: absolute;
        bottom: -50%; /* 图片从底部开始 */
        animation: slide-up 1s ease-in-out forwards;
        width: 568rem;
        height: 280rem;
        margin-left: 71rem;
      }
    }
    .swiper-01 {
      .background(513rem, url(@/assets/image/icon-swiper-mini01.png), 310rem);
      margin-right: 25rem;
    }
    .swiper-02 {
      .background(513rem, url(@/assets/image/icon-swiper-mini01.png), 310rem);
      margin-right: 25rem;
    }
    .swiper-03 {
      .background(513rem, url(@/assets/image/icon-swiper-mini01.png), 310rem);
    }
  }
  &__swiper-tips {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    margin-top: 35rem;
    .tips-item {
      width: 8px;
      height: 8px;
      background: #a9d3bf;
      border-radius: 4px;
      transition: 0.5s;
      margin-right: 10rem;
    }
    .item-active {
      width: 50px !important;
      background: #10b367 !important;
      transition: 0.5s;
    }
  }
}
@keyframes slide-up {
  to {
    bottom: 0; /* 动画结束时图片到达顶部 */
  }
}

.service {
  .background(610rem,url(@/assets/image/bg-server.png));
  padding-top: 60rem;
  &__title {
    font-weight: 400;
    font-size: 40rem;
    color: #223937;
    line-height: 40rem;
    text-align: center;
    margin: 0rem auto 17rem;
  }
  &__gif {
    display: flex;
    justify-content: center;
    margin: 72rem 0 32rem;
  }
  .gif-img {
    width: 449rem;
    height: 314rem;
  }
  &__describe,
  &__bottom {
    font-weight: 400;
    font-size: 22rem;
    color: #52645f;
    line-height: 20rem;
    letter-spacing: 2rem;
    text-align: center;
    margin: 0 auto 0;
  }
}
.bottom {
  height: 60rem;
  background: #354862;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0 100rem;
  p {
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    font-size: 14rem;
    color: #ffffff;
    line-height: 20rem;
    letter-spacing: 1rem;
    text-align: center;
  }
}
</style>